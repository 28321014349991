<template>
  <div v-frag>
    <v-container fluid>
      <Alert/>
      <v-row align='center' align-content='center' justify='center'>
        <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
          <v-card class='rounded-lg' elevation='0'>
            <v-card-title>
              <v-row>
                <v-col cols='12' md='5' class='font-weight-bold'>
                  {{ sections.administrators || 'Administrators title' }}
                </v-col>
                <v-col cols='12' lg='5' md='5' sm='12' xl='5' xs='12'>
                  <v-text-field
                    v-model='search'
                    :loading='loading'
                    :readonly='loading'
                    class='rounded-lg'
                    dense
                    filled
                    hide-details
                    placeholder='Search'
                    prepend-inner-icon='mdi-magnify'
                    rounded
                  ></v-text-field>
                </v-col>
                <v-col cols='12' md='2'>
                  <v-btn
                    block
                    class='white--text text-capitalize'
                    color='primary'
                    height='100%'
                    outlined
                    @click='openDialog(null)'
                  >
                    <v-icon>mdi-plus</v-icon>
                    Add Administrator
                  </v-btn>
                </v-col>
                <div v-if='selected.length' v-frag>
                  <v-col cols='12' md='10'>
                    <div v-frag>
                      <v-btn
                        active-class='red-light'
                        class='red--text text-capitalize'
                        color='red'
                        outlined
                        @click='openDialogDelete(null)'
                      >
                        <v-icon color='red'>mdi-close-thick</v-icon>
                        <b class='red--text text-capitalize'>Delete</b>
                      </v-btn>
                    </div>
                  </v-col>
                </div>
              </v-row>
            </v-card-title>
            <v-data-table
              v-model='selected'
              :footer-props='{
                disablePagination: loading,
                disableItemsPerPage: loading
              }'
              :headers='headers'
              :items='administrators'
              :loading='loading'
              :options.sync='options'
              :server-items-length='adminsNumber'
              :single-select='false'
              checkboxColor='primary'
              elevation='0'
              item-key='id'
              show-select
            >
              <template #item.impersonate={item}>
                <v-btn
                  :loading='loadingImpersonate && item.id === adminId'
                  class='mr-2 my-1 pa-2 primary white--text rounded-sm'
                  dense
                  min-height='35'
                  min-width='35'
                  @click='impersonate(item.id)'
                >
                  <v-icon>mdi-account-arrow-right</v-icon>
                </v-btn>
              </template>
              <template #item.action={item}>
                <v-btn
                  :disabled='selected.length > 0'
                  class='mr-2 my-1 pa-2 primary white--text rounded-sm'
                  dense
                  min-height='36'
                  min-width='36'
                  @click='openDialog(item.id)'
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  :disabled='selected.length > 0'
                  class='mr-2 my-1 pa-2 red white--text rounded-sm'
                  dense
                  min-height='36'
                  min-width='36'
                  @click='openDialogDelete(item)'
                >
                  <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model='dialogDelete' max-width='700px'>
        <v-card class='d-flex flex-column align-start pa-2'>
          <v-card-title class='text-center mb-5'>
            <v-icon class='mr-4 white--text' color='warning' large left>
              mdi-alert
            </v-icon>
            Delete User
          </v-card-title>
          <v-card-subtitle>
            <b>You are about to delete the following user:</b>
          </v-card-subtitle>
          <v-col cols='12'>
            <v-data-table
              :headers='deleteUsersHeaders'
              :items='usersToDelete'
              hide-default-footer
            >
              <template #item.name={item}>
                {{ item.user_name }}
              </template>
              <template #item.profile={item}>
                {{ item.user_profile }}
              </template>
            </v-data-table>
          </v-col>
          <v-card-actions class='align-self-center'>
            <v-spacer></v-spacer>
            <v-btn
              class='px-14 mx-4 font-weight-bold text-capitalize'
              color='primary'
              large
              outlined
              @click='closeDialogDelete'
            >
              Cancel
            </v-btn>
            <v-btn
              :loading='loadingDelete'
              class='px-14 mx-4 font-weight-bold text-capitalize white--text'
              color='warning'
              large
              @click='selectDelete'
            >
              Delete
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <Dialog @update='initialLoad'/>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Dialog from './components/Dialog'
import Alert from '../../components/Alert'
import userService from '../../services/user'
import getSections from '../../services/userOperations/sectionsContent.js'

export default {
  components: {
    Dialog,
    Alert
  },
  data() {
    return {
      loadingImpersonate: false,
      dialog: true,
      administrators: [],
      selected: [],
      adminsNumber: 0,
      adminId: -1,
      loading: false,
      options: {},
      search: '',
      searchTimer: null,
      userToDelete: {},
      usersToDelete: [],
      loadingDelete: false,
      dialogDelete: false,
      headers: [
        { text: 'Name', align: 'start', sortable: true, value: 'user_name' },
        { text: 'Date', align: 'start', sortable: true, value: 'created_at' },
        { text: 'Profile - entity', align: 'start', sortable: true, value: 'user_profile' },
        { text: 'Impersonate', align: 'start', sortable: false, value: 'impersonate' },
        { text: 'Action', align: false, sortable: false, value: 'action' }
      ],
      deleteUsersHeaders: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Profile', align: 'start', sortable: true, value: 'profile' },
        { text: 'Entity', align: 'start', sortable: true, value: 'entity' }
      ]
    }
  },
  computed: {
    ...mapState({
      sections: s => s.cmsStore.sections
    }),
    ...mapGetters({
      user: 'user',
      token: 'token'
    })
  },
  watch: {
    options: {
      async handler() {
        await this.initialLoad()
      }
    },
    search() {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(async () => {
        this.options.page === 1 ? await this.initialLoad() : this.options.page = 1
      }, 750)
    }
  },
  methods: {
    ...mapActions({
      alert: 'alert',
      crud: 'crud',
      userCopy: 'userCopy',
      setUser: 'user',
      setToken: 'token',
      setImpersonal: 'impersonal',
      setImage: 'image'
    }),
    ...mapMutations({
      SET_IMPERSONAL_SIDEBAR: 'SET_IMPERSONAL_SIDEBAR',
      SET_PAGES_CONTENT: 'cmsStore/SET_PAGES_CONTENT'
    }),
    async initialLoad() {
      this.loading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const params = {
        page,
        sort: sortBy[0],
        method: sortBy[0] ? (sortDesc[0] ? 'DESC' : 'ASC') : undefined,
        itemsPerPage: itemsPerPage === -1 ? this.adminsNumber : itemsPerPage,
        search: this.search || undefined
      }
      try {
        const { data, meta: { total } } = await userService.index(params)
        this.administrators = data
        this.adminsNumber = total
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    openDialog(id) {
      let text = id == null ? 'New Administrator' : 'Edit Administrator'
      this.crud({
        dialog: true,
        title: text,
        id: id
      })
    },
    async deleteItem() {
      try {
        const res = await userService.deleted(this.userToDelete.id)
        await this.alert({
          show: true,
          color: 'green',
          title: 'Success message!',
          message: res.message,
          response: true
        })
        this.closeDialogDelete()
      } catch (e) {
        console.log(e)
      }
    },
    openDialogDelete(user) {
      if (user) {
        this.userToDelete = user
        this.usersToDelete.push(user)
      } else {
        this.usersToDelete = this.selected
      }
      this.dialogDelete = true
    },
    closeDialogDelete() {
      this.userToDelete = {}
      this.usersToDelete = []
      this.dialogDelete = false
    },
    async selectDelete() {
      this.loadingDelete = true
      if (this.selected.length) {
        await this.deleteItems()
      } else {
        await this.deleteItem()
      }
      this.loadingDelete = false
      await this.initialLoad()
    },
    async deleteItems() {
      const deleteIds = this.selected.map(el => el.id)
      try {
        const res = await userService.deletedItems(deleteIds)
        await this.alert({
          show: true,
          color: 'green',
          title: 'Success message!',
          message: res.message,
          response: true
        })
        this.closeDialogDelete()
        this.selected = []
      } catch (e) {
        console.log(e)
      }
    },
    async impersonate(id) {
      this.loadingImpersonate = true
      this.adminId = id
      try {
        const res = await userService.impersonate(id)
        if (res.status === 200) {
          await this.userCopy({ user: this.user, token: this.token })
          let sections = {}
          this.SET_IMPERSONAL_SIDEBAR(res.data.data.operations)
          sections = getSections(res.data.data.operations)
          this.SET_PAGES_CONTENT(sections)
          await this.setUser(res.data.data.user)
          await this.setToken(res.data.data.token)
          await this.setImpersonal(true)
          await this.$router.replace(res.data.data.operations[0].default_path)
          await this.setImage()
        }
        this.loadingImpersonate = false
      } catch (e) {
        this.loadingImpersonate = false
      }
    }
  }
}
</script>
